const submissions = (state = { image: null, roasts: null }, { type, payload }) => {
  switch (type) {

    case 'TAKE_PHOTO_FULFILLED':
      return payload;

    case 'FETCH_ROASTS_FULFILLED':
      return payload;

    default:
      return state;
  }
};

export default submissions;
