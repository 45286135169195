import React from 'react';
import './Fire.css';

function Fire() {
  return (
    <div>
      <div className="fire">
        <div className="fire-left">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-main">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-right">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-bottom">
          <div className="main-fire"></div>
        </div>
      </div>
      <p
        style={{
          color: 'white',
          fontFamily: "font-family: 'Roboto', sans-serif",
          fontSize: 30,
          margin: '0 0 0 0',
          paddingTop: 20,
          textAlign: 'center'
        }}
      >
        Computing your roasts!
      </p>
    </div>
  );
}

export default Fire;
