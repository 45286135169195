import React, { useState } from 'react';
import { default as WebCam } from 'react-html5-camera-photo';
import Upload from '../components/Upload';
import 'react-html5-camera-photo/build/css/index.css';
import backArrow from '../images/back-arrow.png';
import { useDispatch } from 'react-redux';
import { takePhoto, goIntroduction } from '../actions';

const style = {
  container: {
    display: 'table',
    width: '100%'
  },
  backButton: {
    top: 25,
    left: '5%',
    color: 'white',
    width: 40,
    position: 'absolute'
  },
  errorMessage: {
    color: 'white',
    fontFamily: "font-family: 'Roboto', sans-serif",
    fontSize: 30,
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '5%',
    maxWidth: 800,
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'table-cell'
  }
};

const Camera = () => {
  const [cameraError, setCameraError] = useState(false);
  const dispatch = useDispatch();

  const onTakePhoto = image => {
    dispatch(takePhoto(image));
  };

  return (
    <div style={style.container}>
      <img
        alt=""
        src={backArrow}
        style={style.backButton}
        onClick={() => dispatch(goIntroduction())}
      />

      {!cameraError ? (
        <WebCam
          onCameraError={() => setCameraError(true)}
          onCameraStart={() => setCameraError(false)}
          onTakePhoto={onTakePhoto}
          imageCompression={0.96}
          imageType="jpg"
        />
      ) : null}

      {cameraError ? (
        <div
          style={{
            marginLeft: 'calc(49% - 52px)',
            width: 104,
            paddingTop: 100
          }}
        >
          <Upload onTakePhoto={onTakePhoto} />
        </div>
      ) : null}
    </div>
  );
};

export default Camera;
