import React, { useEffect } from 'react';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';
import pinterest from '../images/pinterest.png';
import mail from '../images/mail.png';
import linkedin from '../images/linkedin.png';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { goCamera, fetchRoasts } from '../actions';
import camera from '../images/camera.png';

const Introduction = () => {
  const style = {
    socialButtonContainer: {
      top: 25,
      right: '5%',
      position: 'absolute'
    },
    socialButton: {
      color: 'white',
      width: 40,
      marginRight: 15
    }
  };

  const dispatch = useDispatch();
  const loading = useSelector(state => state.submissions.length === 0);

  useEffect(() => {
    dispatch(fetchRoasts());
  });

  return (
    <div>
      <div
        style={{
          color: 'white',
          fontFamily: "'Roboto', sans-serif",
          fontSize: 20,
          paddingLeft: 10,
          paddingRight: 10,
          maxWidth: 800,
          margin: '0 auto',
          textAlign: 'left',
          marginTop: 25
        }}
      >
        <b>About</b>
        <p style={{ marginLeft: 10 }}>
          {
            'Roast Bot uses facial recognition technology to match you with people on the '
          }
          <a
            href="https://reddit.com/r/roastme"
            target="_blank"
            rel="noopener noreferrer"
          >
            r/roastme
          </a>
          {
            ' subreddit and serves you hilarious and humbling roasts from those matches. This enables you to find great roast content without ever having to share your face publicly online!'
          }
        </p>

        <b>Author</b>
        <p style={{ marginLeft: 10 }}>
          This project is designed and developed by Isaiah Nields. Find out more
          at <a href="https://isaiahnields.com">isaiahnields.com</a>.
        </p>

        <b>Share</b>

        <div style={{ marginLeft: 15 }}>
          <img
            alt=""
            src={facebook}
            style={style.socialButton}
            onClick={() =>
              window.open(
                'https://www.facebook.com/sharer/sharer.php?u=https://isaiahnields.com/roast-bot',
                '_blank'
              )
            }
          />

          <img
            alt=""
            src={twitter}
            style={style.socialButton}
            onClick={() =>
              window.open(
                'https://twitter.com/intent/tweet?text=Check out Roast Bot and get flamed by an AI! https://isaiahnields.com/roast-bot ',
                '_blank'
              )
            }
          />

          <img
            alt=""
            src={pinterest}
            style={style.socialButton}
            onClick={() =>
              window.open(
                'https://pinterest.com/pin/create/button/?url=https://isaiahnields.com/roast-bot&media=&description=Check out Roast Bot and get flamed by an AI!',
                '_blank'
              )
            }
          />

          <img
            alt=""
            src={linkedin}
            style={style.socialButton}
            onClick={() =>
              window.open(
                'https://www.linkedin.com/shareArticle?mini=true&url=https://isaiahnields.com/roast-bot&title=&summary=Check out Roast Bot and get flamed by an AI!&source=',
                '_blank'
              )
            }
          />

          <img
            alt=""
            src={mail}
            style={style.socialButton}
            onClick={() =>
              window.open(
                'mailto:info@example.com?&subject=&body=https://isaiahnields.com/roast-bot Check out Roast Bot and get flamed by an AI!',
                '_blank'
              )
            }
          />
        </div>
        <div style={{ position: 'absolute', bottom: '2%', left: '2%', width: '96%' }}>
          <Button
            style={{ width: '100%', marginBottom: 10, fontSize: 20 }}
            size="large"
            type="primary"
            onClick={() => dispatch(goCamera())}
            loading={loading}
          >
            <img
              src={camera}
              alt="camera"
              style={{ width: 25, marginBottom: 5 }}
            ></img>
            &nbsp;{' Start'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
