import React from 'react';
import { Card, Avatar } from 'antd';
import redditLogo from '../images/reddit-logo.png';

const { Meta } = Card;

const Submission = ({
  image,
  title,
}) => {

  const style = {
    title: {
      fontFamily: 'Roboto',
      color: 'white',
      width: '90%',
      maxWidth: 900,
      marginRight: 'auto',
      marginLeft: 'auto',
      borderWidth: 0,
      marginTop: 15
    },
    image: {
      maxHeight: 900,
      objectFit: 'cover',
      objectPosition: '0% 5%'
    }
  };

  return (
    <div>
      <Card
        hoverable
        style={style.title}
        cover={
          <img
            alt="Roast Me Match"
            src={image}
            style={style.image}
          />
        }
      >
        <Meta
          title={title}
          avatar={<Avatar src={redditLogo} />}
        />
      </Card>
    </div>
  );
};

export default Submission;
