import React from 'react';
import Introduction from './containers/Introduction';
import Camera from './containers/Camera';
import Loading from './containers/Loading';
import Roasts from './containers/Roasts';
import { useSelector } from 'react-redux';
import { States } from './reducers';
import 'antd/dist/antd.css';
import './App.css';

const App = () => {
  const styles = {
    title: {
      color: 'white',
      fontFamily: "'Reenie Beanie', cursive",
      fontWeight: 'lighter',
      fontSize: 60,
      paddingBottom: 0,
      paddingTop: 0,
      textAlign: 'center',
      marginLeft: '10%',
      marginRight: '10%',
      marginBottom: 0
    }
  };

  const view = useSelector(state => state.view);

  return (
    <div className="App">
      <h1 style={styles.title}>Roast Bot</h1>

      {view === States.INTRODUCTION ? <Introduction /> : null}

      {view === States.CAMERA ? <Camera /> : null}

      {view === States.LOADING ? <Loading /> : null}

      {view === States.ROASTS ? <Roasts /> : null}
    </div>
  );
};

export default App;
