export const States = Object.freeze({
  INTRODUCTION: Symbol('introduction'),
  CAMERA: Symbol('camera'),
  LOADING: Symbol('loading'),
  ROASTS: Symbol('roasts')
});

const view = (state = States.INTRODUCTION, { type }) => {
  switch (type) {
    case 'GO_INTRODUCTION':
      return States.INTRODUCTION;
    case 'GO_CAMERA':
      return States.CAMERA;
    case 'TAKE_PHOTO_PENDING':
      return States.LOADING;
    case 'TAKE_PHOTO_FULFILLED':
      return States.ROASTS;
    default:
      return state;
  }
};

export default view;
