import axios from 'axios';

const _fetchRoasts = () =>
  import('./scrape.json').then(data => {
    return {
      roasts: Object.values(data)
        .slice(0, -1)
        .map(roast => Object({ ...roast, similarity: null })),
      image: null,
    }
  });

const getEmbedding = image =>
  axios
    .post(
      'https://0uaje48t0j.execute-api.us-east-1.amazonaws.com/default/roast',
      image.substring(23),
      {
        headers: {
          'Content-Type': 'text/plain'
        }
      }
    )
    .then(res => res.data);

const dotProduct = (a, b) => {
  let total = 0;
  for (let i = 0; i < a.length; i++) total += a[i] * b[i];
  return total;
};

const calculateSimilarities = (embedding, roasts) => {
  return roasts.map(roast => Object({ ...roast, similarity: dotProduct(roast.embedding, embedding) }));
};

const fetchRoasts = () => ({
  type: 'FETCH_ROASTS',
  payload: _fetchRoasts()
});

const takePhoto = image => ({
  type: 'TAKE_PHOTO',
  payload: Promise.all([getEmbedding(image), _fetchRoasts()]).then(pair => {
    return { roasts: calculateSimilarities(pair[0], pair[1].roasts), image }
  })
});

export { fetchRoasts, takePhoto };
