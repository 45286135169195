import React from 'react';
import { Card, Icon } from 'antd';
const { Meta } = Card;

function Roast({ body, score, id, submissionId }) {
  return (
    <Card
      hoverable
      style={{
        width: '90%',
        maxWidth: 900,
        marginTop: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
      }}
    >
      <Meta
        avatar={<Icon type="message" style={{ fontSize: '25px' }} />}
        title={`${Math.round(score * 100)}% facial match`}
        description={body}
      />
      <div style={{ display: 'none' }}>
        Submission ID: {submissionId}
        Comment ID: {id}
      </div>
    </Card>
  );
}

export default Roast;
