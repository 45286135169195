import { combineReducers } from 'redux';
import submissions from './submissions';
import view, { States } from './view';

export default combineReducers({
  submissions,
  view
});

export { States };
