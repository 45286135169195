import React from 'react';
import refresh from '../images/refresh.png';
import { useDispatch, useSelector } from 'react-redux';
import { goCamera } from '../actions';
import Roast from '../components/Roast';
import Submission from '../components/Submission';

const Roasts = () => {
  const style = {
    refreshButton: {
      top: 25,
      left: '5%',
      width: 40,
      position: 'absolute'
    },
    spinContainer: {
      textAlign: 'center',
      borderRadius: 4,
      marginBottom: 20,
      padding: '30px 50px',
      margin: '20px 0'
    }
  };

  const dispatch = useDispatch();

  let roasts = useSelector(({ submissions: { roasts } }) => {
    roasts = roasts.map(submission => {
      return submission.comments.map(comment => {
        return {
          submissionId: submission.id,
          ...comment,
          similarity: submission.similarity,
          submissionScore: submission.score,
          commentScore: comment.score,
          score: submission.similarity * Math.min(Math.max((comment.score / submission.score), 0.9), 1.1)
        };
      });
    }).flat();

    roasts.sort((a, b) => b.score - a.score)
    return roasts;
  });

  let image = useSelector(({ submissions: { image } }) => image);

  const onClickRefresh = () => dispatch(goCamera());

  return (
    <div>
      <img
        alt=""
        style={style.refreshButton}
        onClick={onClickRefresh}
        src={refresh}
      />
      <Submission image={image} title={'Get Roasted!'} />
      {roasts.map(submission => (
        <Roast key={submission.id} {...submission} />
      ))}
    </div>
  );
};

export default Roasts;
